<template>
  <div class="lottery-turntable-component full-screen">
    <LuckyGrid
      class="lucky-grid"
      ref="luckyGrid"
      width="300px"
      height="300px"
      :prizes="prizes"
      :blocks="blocks"
      :buttons="buttons"
      :default-style="defaultStyle"
      :active-style="activeStyle"
      @start="startCallBack"
      @end="endCallBack"
    />
  </div>
</template>

<script>
export default {
  name: "LotteryTurntable",
  data() {
    this.defaultStyle = {
      fontColor: "#AFFFD7",
      fontSize: "10px",
      lineHeight: "13px",
      wordWrap: false,
    };
    this.activeStyle = {
      fontColor: "#fff",
    };
    this.blocks = [
      { padding: "10px", background: "rgba(0, 0, 0, 0)", borderRadius: "10px" },
    ];
    this.buttons = [
      {
        x: 1,
        y: 1,
        background: "rgba(0, 0, 0, 0)",
        fonts: [],
        imgs: [
          {
            src: require("../assets/images/button.png"),
            width: "100%",
            top: "0",
          },
        ],
      },
    ];
    return {
      prizes: [],
    };
  },

  methods: {
    getGiftList() {
      const prizes = [];
      let axis = [[0, 0], [1, 0], [2, 0], [2, 1], [2, 2], [1, 2], [0, 2], [0, 1],];
      let data = [
        { name: "Dior双飞套装", img: require("/public/images/1.png"), top: "70%",},
        { name: "腾讯终身VIP", img: require("/public/images/2.png"), top: "70%" },
        { name: "兰蔻小黑瓶", img: require("/public/images/3.png"), top: "70%" },
        { name: "520红包", img: require("/public/images/6.png"), top: "70%" },
        { name: "Dior星空套装", img: require("/public/images/5.png"), top: "70%" },
        { name: "1314红包", img: require("/public/images/6.png"), top: "70%" },
        { name: "UR购物券", img: require("/public/images/7.png"), top: "70%" },
        { name: "Dyson美发套装", img: require("/public/images/8.png"), top: "70%" },
      ];
      // let data = [];
      data.forEach((item, index) => {
        prizes.push({
          x: axis[index][0],
          y: axis[index][1],
          title: item.name,
          fonts: [{ text: item.name, top: item.top }],
          imgs: [
            {
              src: require("../assets/images/block-bg.png"),
              activeSrc: require('../assets/images/active-block-bg.png'),
              width: "100%",
              height: "100%",
            },
            {
              src: item.img,
              width: "70%",
              top: "3%",
            },
          ],
        });
      });
      this.prizes = prizes;
    },
    startCallBack () {
      this.$refs.luckyGrid.play()
      setTimeout(() => {
        this.$refs.luckyGrid.stop(Math.random() * 8 >> 0)
      }, 7000)
    },
    endCallBack (prize) {
      const { imgs, title } = prize;
      const imageUrl = imgs[1].src;
      localStorage.setItem('HAS_GIFT', '1');
      localStorage.setItem('GIFT_NAME', title);
      localStorage.setItem('GIFT_IMAGE_URL', imageUrl);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  },

  mounted() {
    this.getGiftList();
  }, 
};
</script>

<style scoped>
.lottery-turntable-component {
  background-color: #fad3e6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lucky-grid {
  width: 300px;
  height: 300px;
  background: rgb(245, 171, 184);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
</style>